
<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 no-padding">

            <div v-if="hasRedemptionData" class="card col s12 vert-offset-top-1 padding-top-half">
                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-half">
                    <div class="col s12 padding-bottom-half padding-right-0 padding-left-0 vert-offset-top-half vert-offset-bottom-half border-bottom-1 vert-offset-bottom-half grey-border border-lighten-3 align-middle">
                        <div class="col s7 no-padding grey-text text-darken-2 font-12">
                            <div class="col s12 no-padding" v-if="redemption_activation">
                                <i class="material-icons tiny green-text text-darken-2">lens</i>
                                <span class="vert-offset-left-half">You qualify yet to redeem discounts</span>
                            </div>
                            <div class="col s12 no-padding" v-else>
                                <i class="material-icons tiny red-text text-darken-2">lens</i>
                                <span class="vert-offset-left-half">You do not qualify yet to redeem discounts</span>
                            </div>
                        </div>
                        <div class="col s5 no-padding right-align">
                                    <span class="homeboy-charcoal-black padding-top-half padding-bottom-half padding-left-half padding-right-half font-14 right-align">
                                               <span class="no-padding white-text font-bold ">
                                            EP SCORE
                                        </span>
                                        <span class="padding-left-half padding-right-half font-bold homeboy-gold-text  padding-bottom-half">
                                            {{epscore}}
                                        </span>
                                        </span>
                        </div>


                    </div>

                    <div class="col s12 no-padding">
                        <div class="col s12 padding-bottom-half padding-right-0 padding-left-0 vert-offset-top-half vert-offset-bottom-half border-bottom-1 vert-offset-bottom-half grey-border border-lighten-3 align-middle">
                            <div class="col s8 no-padding grey-text text-darken-2">
                                Redemption Points
                            </div>
                            <div class="col s4 right-align black-text font-bold font-14 black-text align-middle">

                                        <span class="grey-text text-darken-2">
                                            {{ points.current }}
                                        </span>

                                <a @click.prevent="toastRedemptionPoints" :title="showRedemptionPoints" class="clickable">
                                    <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                </a>
                            </div>
                        </div>

                        <div class="col s12 padding-bottom-half padding-right-0 padding-left-0 vert-offset-top-half vert-offset-bottom-half border-bottom-1 vert-offset-bottom-half grey-border border-lighten-3 align-middle">
                            <div class="col s8 no-padding grey-text text-darken-2">
                                Month To Date Spend

                                <i v-if="(redemption.spend < redemption.threshold.spend)" class="material-icons tiny red-text text-darken-2 font-10">lens</i>
                                <i v-else class="material-icons tiny green-text text-darken-2 font-10">lens</i>
                            </div>
                            <div class="col s4 right-align black-text font-bold font-14 black-text align-middle">

                                        <span class="grey-text text-darken-2">
                                            {{mtd_spend}}
                                        </span>
                                <a @click.prevent="toastMonthSpend" :title="showMonthSpend" class="clickable">
                                    <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                </a>
                            </div>
                        </div>
                        <div class="col s12 padding-bottom-half padding-right-0 padding-left-0 vert-offset-top-half vert-offset-bottom-half border-bottom-1 vert-offset-bottom-half grey-border border-lighten-3 align-middle">
                            <div class="col s8 no-padding grey-text text-darken-2">
                                Month To Date Spread

                                <i v-if="(redemption.spread < redemption.threshold.spread)" class="material-icons tiny red-text text-darken-2 font-10">lens</i>
                                <i v-else class="material-icons tiny green-text text-darken-2 font-10">lens</i>
                            </div>
                            <div class="col s4 right-align black-text font-bold font-14 black-text align-middle">


                                        <span class="grey-text text-darken-2">
                                            {{mtd_spread}}
                                        </span>
                                <a @click.prevent="toastMonthSpread" :title="showMonthSpread" class="clickable">
                                    <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="card col s12 vert-offset-top-half padding-top-half">
                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-half">

                    <div class="row col s12 vert-offset-top-half">
                        <div class="row" v-if="isSales && transactionsUpdated">
                            <div class="col s12 border-bottom-1 grey-border border-lighten-3 vert-offset-bottom-half padding-right-0">
                                <div class="col s10 no-padding">
                                    <h6 class="homeboy-charcoal-black-text font-bold">Transactions History</h6>
                                </div>
                                <div class="col s2 no-padding text-align-right">
                                    <a
                                            class="homeboy-charcoal-black-text vert-offset-right-half vert-offset-left-half padding-default clickable"
                                            @click.prevent="showRedemptions()"
                                            title="Redemptions History"
                                    >
                                        <i class="material-icons">card_giftcard</i>
                                    </a>

                                </div>
                            </div>
                            <div class="col s12 no-padding" v-if="customer_transactions != null && customer_transactions.length > 0">
                                <table class="striped">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Site</th>
                                        <th>Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="font-12" v-for="(item, index) in customer_transactions" :key="index">
                                        <td>
                                            {{item.date}}
                                        </td>
                                        <td>
                                            {{matchPurchaseLocation(item)}}
                                        </td>
                                        <td>
                                            {{item.amount}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="col s12 grey-text text-darken-2">
                                <h6>No Transactions recorded</h6>
                            </div>

                        </div>
                        <div class="row" v-if="!isSales && redemptionsUpdated">
                            <div class="col s12 border-bottom-1 grey-border border-lighten-3 vert-offset-bottom-half padding-right-0">
                                <div class="col s10 no-padding">
                                    <h6 class="homeboy-charcoal-black-text font-bold">Redemptions History</h6>
                                </div>
                                <div class="col s2 no-padding text-align-right">
                                    <a
                                            class="homeboy-charcoal-black-text vert-offset-right-half vert-offset-left-half padding-default clickable"
                                            @click.prevent="showSales()"
                                            title="Transactions History"
                                    >
                                        <i class="material-icons">swap_vert</i>
                                    </a>

                                </div>
                            </div>
                            <div class="col s12 no-padding" v-if="customer_redemptions != null && customer_redemptions.length > 0">

                                <table class="striped" >
                                    <thead class="font-12">
                                    <tr>
                                        <th></th>
                                        <th>Date</th>
                                        <th>Site</th>
                                        <th>Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="font-10" v-for="(item, index) in customer_redemptions" :key="index">
                                        <td>{{((current_page-1)*per_page)+index+1}}</td>
                                        <td class="font-9">{{item.date}}</td>
                                        <td class="font-9"> {{matchPurchaseLocation(item.purchase.data)}}</td>
                                        <td>{{item.amount}}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div class="col s12 vert-offset-top-half padding-top-1 grey-border border-top-1 border-lighten-2">
                                    <div class="col s4 left-align" >
                                        <button class="btn pink darken-2" v-if="showPrevious" @click.prevent="loadPrevious">Previous</button>
                                    </div>
                                    <div class="col s4 center font-12">
                                        Page {{ current_page }}
                                    </div>
                                    <div class="col s4 right-align">
                                        <button class="btn pink darken-2" v-if="showNext" @click.prevent="loadNext">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col s12 grey-text text-darken-2">
                                <h6>No redemptions recorded</h6>

                            </div>
                        </div>





                    </div>
                </div>


            </div>
        </div>

    </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex";
    import http from "../../../../http-common";
    import M from "materialize-css";


    export default {
        name: "HomeLoyalToLocalComponent",
        components:{
        },
        data() {
            return {
                mtd_spend:'',
                epscore:'',
                customer_transactions:null,
                redemption_activation:'',
                mtd_spread:'',
                customer_info:'',
                activeClass: "material-icons green-text text-darken-2 left tiny",
                inactiveClass: "material-icons green-text text-darken-2 left tiny",
                siteids:null,
                organisationids:null,
                base_url:"l2l/customer/redemptions/list",
                current_page:1,
                per_page: 0,
                pagination:null,
                customer_redemptions:null,
                links:null,
                isSales:true,
                customer_sites:null,
                customer_organisations:null,
                points: 0,
                thresholds: {
                    spend: null,
                    spread: null
                },
                redemption: null,
                transactionsUpdated: false,
                redemptionsUpdated: false,

            }
        },
        created () {
            this.getCustomerInfo();
            this.getCustomerTransactions();
            this.getCustomersRedemptions();
            this.loadPage();


        },
        computed: {
            ...mapState({
                selected_supplier:state=>state.loyaltolocal.l2l_selected_supplier,
                selected_organisation:state=>state.loyaltolocal.l2l_selected_organisation,
                services_list:state=>state.services.services,
                l2l_alias:state=>state.services.service_alias_loyal_to_local,
                supplier_subscription:state=>state.loyaltolocal.l2l_supplier_subscription,
                user:state=>state.users.organisation_user,
                customer:state=>state.customers.customerInfo,
                selected_service:state=>state.customers.customer_selected_service,

            }),
            hasRedemptionData: function()
            {
                return (typeof this.redemption !== "undefined" && this.redemption !== null);
            },
            showPrevious:function ()
            {
                if(typeof this.l2l_customer_redemptions === "undefined" || this.l2l_customer_redemptions === null || this.l2l_customer_redemptions.length <= 0)
                {
                    return false;
                }

                if(typeof this.links === "undefined" || this.links === null)
                {
                    return false;
                }
                if(typeof this.links.previous === "undefined" || this.links.previous === null)
                {
                    return false;
                }

                return true;


            },
            showNext:function ()
            {
                if(typeof this.l2l_customer_redemptions === "undefined" || this.l2l_customer_redemptions === null || this.l2l_customer_redemptions.length <= 0)
                {
                    return false;
                }
                if(typeof this.links === "undefined" || this.links === null)
                {
                    return false;
                }

                if(typeof this.links.next === "undefined" || this.links.next === null)
                {
                    return false;
                }

                return true;
            },
            showMonthSpend: function()
            {
                if(!this.hasRedemptionData)
                    return "";

                return "This is the amount you spent on HomeBoys for the current month. You need a minimum of "+this.redemption.threshold.spend + " to redeem your discount points.";
            },
            showMonthSpread: function()
            {
                if(!this.hasRedemptionData)
                    return "";

                return "This is the number of HomeBoys you supported so far this month. You need a minimum of "+this.redemption.threshold.spread;
            },
            showRedemptionPoints: function()
            {
                if(!this.hasRedemptionData)
                    return "";

                return "This is your total number of available redemption points. You need a minimum of "+this.points.status.minimum + " to be able to redeem discounts.";
            },
        },
        methods : {
            toastMonthSpread: function()
            {
                M.toast({html: "<span class='font-12'>"+this.showMonthSpread+"</span>"});
            },

            toastMonthSpend: function()
            {
                M.toast({html: "<span class='font-12'>"+this.showMonthSpend+"</span>"});
            },
            toastRedemptionPoints: function()
            {
                M.toast({html: "<span class='font-12'>"+this.showRedemptionPoints+"</span>"});
            },
            loadPage: function()
            {
                let _header = null;
                if(typeof this.services_list !== "undefined" && this.services_list !== null && this.services_list.length > 1)
                {
                    _header = {
                        title: this.service,
                        icon: "chevron_left",
                        url: "/customer/home"
                    };
                }
                this.$setPageHeader(_header);
                this.loadMenu();
            },
            loadMenu: function()
            {
                this.$store.dispatch('COMMIT_SET_MENU_LINKS', [
                    {
                        title : "Find Supplier",
                        link: "/search/supplier",
                        icon: "search"
                    }
                ]);
            },
            getCustomerInfo:function () {

                console.log("Customer:"+this.customer.id);
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({url: 'l2l/customers/info',
                        data:
                            {
                                customer:this.customer.id,
                                source: this.$source
                            },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            /*console.log("Response:" + JSON.stringify(resp));
                            console.log("Data:" + JSON.stringify(resp.data));*/

                            this.mtd_spend = resp.data.data.spend.month;
                            this.mtd_spread = resp.data.data.spread.month;
                            this.redemption_activation = resp.data.data.redemption.activation.status;
                            this.epscore = resp.data.data.ep;
                            this.points = resp.data.data.redemption.points;
                            this.thresholds = resp.data.data.redemption.activation.threshold;
                            this.redemption = resp.data.data.redemption.activation;




                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },
            getCustomerTransactions:function () {
                this.$store.dispatch('START_PROCESSING');
                this.transactionsUpdated = false;
                return new Promise((resolve) => {
                    http({url: 'l2l/customer/purchases/list',
                        data:
                            {
                                customer:this.customer.id,
                                source: this.$source
                            },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            /*console.log("Response:" + JSON.stringify(resp));
                            console.log("Data:" + JSON.stringify(resp.data));*/


                            this.customer_transactions = resp.data.data.data;
                            this.siteids = resp.data.data.site;
                            this.organisationids = resp.data.data.organisation;
                            this.transactionsUpdated = true;



                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },

            onPageChanged:function (page) {

                this.current_page = page
            },
            getCustomersRedemptions:function (url) {

                this.redemptionsUpdated = false;
                if(typeof url === "undefined" || url === null)
                {
                    url = this.base_url;
                }
                else
                {
                    url = "/l2l"+url;
                }
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({url:url,
                        data:
                            {
                                customer : this.customer.id,
                                source: this.$source
                            },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            /*console.log("Response:" + JSON.stringify(resp));
                            console.log("Data:" + JSON.stringify(resp.data));*/

                            this.customer_redemptions = resp.data.data;
                            this.siteids = resp.data.data.site;
                            this.organisationids = resp.data.data.organisation;
                            this.links = resp.data.links;
                            this.pagination = resp.data.pagination;
                            this.redemptionsUpdated = true;
                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },

            loadNext:function ()
            {

                if(this.showNext === true)
                {
                    this.getCustomersRedemptions(this.links.next);
                }
            },
            loadPrevious:function ()
            {
                if(this.showPrevious === true)
                {
                    this.getCustomersRedemptions(this.links.previous);
                }
            },

            showSales:function () {

                this.isSales = true;

            },

            showRedemptions:function () {

                this.isSales = false;

            },

            matchSite:function (site_id) {
                console.log("Site ID: "+site_id);

                if(typeof this.customer_sites === 'undefined' || this.customer_sites === null || this.customer_sites.length <= 0)
                {
                    console.log("typeof this.customer_sites === 'undefined'");

                    return null;
                }

                let arrFilter = this.customer_sites.filter(function (item) {
                    return (item.id === site_id);

                });

                console.log("arrFilter:"+JSON.stringify(arrFilter));

                if(arrFilter.length <= 0)
                {
                    console.log("arrFilter.length <= 0");

                    return null;
                }
                else
                {
                    console.log("MatchSites: "+JSON.stringify(arrFilter[0]));

                    return arrFilter[0].name;
                }

            },
            matchOrganisation:function (_id) {

                if(typeof this.customer_organisations === 'undefined' || this.customer_organisations === null || this.customer_organisations.length <= 0)
                {
                    return null;
                }

                let arrFilter = this.customer_organisations.filter(function (item) {
                    return (item.id === _id);

                });

                if(arrFilter.length <= 0)
                {
                    return null;
                }
                else
                {
                    return arrFilter[0].name;
                }

            },
            matchPurchaseLocation: function(_item)
            {
                if(typeof _item.site !== "undefined" && _item.site !== null && _item.site > 0)
                    return this.matchSite(_item.site);

                return this.matchOrganisation(_item.organisation);
            },
            getSite:function(){
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({url: 'customer/sites/list',
                        data:
                            {
                                sites:this.siteids,
                                source: this.$source
                            },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            /*console.log("Response: getSite:" + JSON.stringify(resp));
                            console.log("Data:" + JSON.stringify(resp.data));*/

                            this.customer_sites = resp.data.data;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },
            getOrganisations:function(){
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({url: 'customer/organisations/list',
                        data:
                            {
                                sites:this.siteids,
                                source: this.$source
                            },
                        method: 'POST',
                        timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');

                            /*console.log("Response: getSite:" + JSON.stringify(resp));
                            console.log("Data:" + JSON.stringify(resp.data));*/

                            this.customer_organisations = resp.data.data;

                            // you have your token, now log in your user :)

                            resolve(resp)
                        })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },
        },

        watch: {
            'pagination': function(_value)
            {
                if(typeof _value=== "undefined" || _value === null)
                    return;

                this.current_page = _value.current_page;
                this.per_page= _value.per_page;
            },
            'siteids':function ()
            {

                if(typeof this.siteids !== "undefined" && this.siteids !== null && !this.siteids.length <= 0)
                {
                    /*console.log("customer IDS: "+JSON.stringify(this.siteids));*/
                    this.getSite();
                }
            },
            'organisationids':function ()
            {

                if(typeof this.organisationids !== "undefined" && this.organisationids !== null && !this.organisationids.length <= 0)
                {
                    this.getOrganisations();
                }
            }
        }
    };
</script>