<template>
  <ul class="tabs">
    <slot></slot>
  </ul>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";

export default {
  name: "tab-component",

  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined
    };
  },

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initializeTab(options = {}) {
      return M.Tabs.init(this.$el, options);
    }
  },
  mounted() {
    console.log("component mounted");
    this.instance = this.initializeTab();
    console.log(this.instance);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
